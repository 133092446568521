import { useCallback, useEffect, useRef } from "react";

export function useWaitFor(ms?: number): (ms?: number) => Promise<void> {
  const timerRef = useRef<ReturnType<typeof setTimeout>>();

  useEffect(
    () => () => {
      if (timerRef.current) clearTimeout(timerRef.current);
    },
    []
  );

  const doTimeout = useCallback(
    async (innerMS?: number) =>
      new Promise<void>((res) => {
        if (timerRef.current) clearTimeout(timerRef.current);
        timerRef.current = setTimeout(res, typeof innerMS === "number" ? innerMS : ms);
      }),
    [ms]
  );

  return doTimeout;
}
